import {
	INTERCOM_IFRAME_SELECTOR,
	INTERCOM_LIGHTWEIGHT_SELECTOR
} from '../hooks/useIntercomStatus';

/**
 * If there was no interaction with the Intercom widget, the iframe is not loaded.
 * In this case, the Intercom widget is rendered as a div with the class 'intercom-lightweight-app'.
 * Once the user interacts with the widget, the iframe is loaded with the class 'intercom-launcher-frame'.
 *
 * This function applies aria-hidden to the SVG icons in the Intercom widget.
 */
export const applyIntercomA11y = (isIntercomLaunched: boolean) => {
	if (isIntercomLaunched) {
		const iframe = document.querySelector<HTMLIFrameElement>(INTERCOM_IFRAME_SELECTOR);

		const iframeDoc = iframe?.contentDocument || iframe?.contentWindow?.document;
		if (!iframeDoc) return;

		iframeDoc.querySelectorAll('svg').forEach((svg) => svg.setAttribute('aria-hidden', 'true'));
	} else {
		document
			.querySelector<SVGSVGElement>(`${INTERCOM_LIGHTWEIGHT_SELECTOR} svg`)
			?.setAttribute('aria-hidden', 'true');
	}
};
