import React, { useEffect } from 'react';

import useIntercomStatus from '../hooks/useIntercomStatus';
import { applyIntercomA11y } from '../utils/intercom';

const Intercom: React.VFC = () => {
	const { isIntercomPresent, isIntercomLaunched } = useIntercomStatus();

	useEffect(() => {
		if (isIntercomPresent) applyIntercomA11y(isIntercomLaunched);
	}, [isIntercomPresent, isIntercomLaunched]);

	return null;
};

export default Intercom;
