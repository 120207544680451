import { useState, useEffect } from 'react';

export const INTERCOM_IFRAME_SELECTOR = '#intercom-container iframe.intercom-launcher-frame';
export const INTERCOM_LIGHTWEIGHT_SELECTOR = '.intercom-lightweight-app';

const useIntercomStatus = () => {
	const [isIntercomPresent, setIsIntercomPresent] = useState(false);
	const [isIntercomLaunched, setIsIntercomLaunched] = useState(false);

	useEffect(() => {
		const observer = new MutationObserver(() => {
			const intercomIframe = document.querySelector<HTMLIFrameElement>(INTERCOM_IFRAME_SELECTOR);
			const intercomLightweight = document.querySelector(INTERCOM_LIGHTWEIGHT_SELECTOR);

			setIsIntercomPresent(!!(intercomIframe || intercomLightweight));
			setIsIntercomLaunched(!!intercomIframe);
		});

		observer.observe(document.body, { childList: true, subtree: true });

		return () => observer.disconnect();
	}, []);

	return { isIntercomPresent, isIntercomLaunched };
};

export default useIntercomStatus;
